import React from 'react';
import { NavLink } from 'react-router-dom';
import RightsCheck from '../General/RightsCheck';
import { scrollToTop } from '../Shared/ScrollOnTop';


function FeeSystemMenu(props) {
    return (

        <>
            <RightsCheck />
            <nav className={`navbar navbar-expand-lg navbar-module MenuSticky`}>
                <div className="container-fluid">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#NavbarSecondary"
                        aria-controls="NavbarSecondary"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="NavbarSecondary">
                        <ul className="navbar-nav  m-auto">
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/FeeSystem/PrintVouchers">Print Vouchers</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/FeeSystem/AddAbsentFine">Add Absent Fine</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/FeeSystem/AddOtherDuesFine">Add Other Dues/Fine</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/FeeSystem/AddBankScroll">Add Bank Scroll</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/FeeSystem/CollectionReport">Collection Report</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/FeeSystem/CollectionReportHeadWise">Collection Report Headwise</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={scrollToTop} className="nav-link" to="/FeeSystem/ChangeStudentStatus">Change Student Status</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default FeeSystemMenu;