import React, { useState, useEffect, useRef } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import FeeSystemMenu from "./FeeSystemMenu";
import BanksDropDown from "../Shared/BanksDropDown";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { scrollToTop } from "../Shared/ScrollOnTop";
import * as XLSX from "xlsx";
import { set } from "lodash";
import MonthsDropDown from "../Shared/MonthsDropDown";
import YearsDropDown from "../Shared/YearsDropDown";

function AddBankScroll(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [MemberType, setMemberType] = useState(
    localStorage.getItem("MemberType")
  );
  const [UserName, setUserName] = useState(localStorage.getItem("FullName"));
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [TotalDeposited, setTotalDeposited] = useState("");
  const [ReceiptNo, SetReceiptNo] = useState("");
  const [ScrollDated, setScrollDated] = useState("");
  const [FineDate, setFineDate] = useState(moment().format(config.date_format_input));
  const [FineMonth, setFineMonth] = useState(moment().format("MM"));
  const [FineYear, setFineYear] = useState(moment().format("YYYY"));
  const [BenchNo, setBenchNo] = useState("");
  const [BarCodeValue, setBarCodeValue] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [lgShow, setLgShow] = useState(false);

  const [Amount_Deposited, setAmount_Deposited] = useState("");
  const [RegistrationNo, setRegistrationNo] = useState("");

  const [LateFeeFineStatus, setLateFeeFineStatus] = useState(false);
  const [LateFineAmount, setLateFineAmount] = useState(0);

  const [loadingPostEntries, setloadingPostEntries] = useState(false);
  const [LoadingFetchLedger, setLoadingFetchLedger] = useState(false);
  const [fileSelected, setfileSelected] = useState(false);

  const [selectedFileName, setSelectedFileName] = useState('');
  const [StudentADLedger, setStudentADLedger] = useState([]);
  const [enteredAmounts, setEnteredAmounts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedStudentID, setSelectedStudentID] = useState(0);
  const [StudentDepositedAmount, setStudentDepositedAmount] = useState(0);
  const [BankID, setBankID] = useState(0);
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [totalRecords, setTotalRecords] = useState(0);
  const [FineAmount, setFineAmount] = useState("");
  const [StruckOffFine, setStruckOffFine] = useState("");

  useEffect(() => {
    document.title = "Add Bank Scroll";
    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Dated: ScrollDated,
      BenchNo: BenchNo,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "ScrollEntry/GetUnPostedEntries",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);
        setTotalDeposited(response.data.TotalDeposited);
        setBankID(response.data.BankID);
      })
      .catch(function (error) {
        //////console.log(error);
      });
  }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)} </td>
            <td>{item.Registration_No}</td>
            <td>{item.Student_Name}</td>
            <td>{item.Receipt_No}</td>
            <td>{item.BenchNumber}</td>
            <td>{moment(item.Dated).format("DD/MM/YYYY")}</td>
            <td className="text-center">{item.Amount_Deposited}</td>
            {/* <td>{item.Balance_After_This_Entry}</td>
            <td>{item.Balance_Type_After_This_Entry}</td> */}
            <td className="text-center">
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <button
                    onClick={(e) => EditEntry(item.Student_ID, item.Amount_Deposited)}
                    className="green"
                  >
                    <i className="ri-edit-line"></i>
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => deleteEntry(item.Entry_Id)}
                    className="red"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const Code_to_Amount = () => {


    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      registrationNo: RegistrationNo,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "ScrollEntry/GetCurrentBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data)
        if (response.data.status_code == 1) {
          setAmount_Deposited(parseInt(response.data.Balance) + parseInt(FineAmount))
          //console.log("Balance", response.data.Balance);
          setIsUpdated(true);
        }
        setIsUpdated(false);
      })
      .catch(function (error) {
        //////console.log(error);
      });
  };


  const deleteEntry = (ID) => {
    const result = window.confirm("Are you sure you want to delete?");

    if (result) {

      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberType: MemberType,
        Entry_Id: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "ScrollEntry/DeleteScrollEntry",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          ////////console.log(response.data);
          if (response.data.status_code == 1) {
            setIsUpdated(true);
          }
          // setIsUpdated(false);
        })
        .catch(function (error) {
          //////console.log(error);
        });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet,
        {
          raw: false,
          dateNF: 'DD/MM/YYYY',
        }
      );
      setfileSelected(true);
      setExcelData(jsonData);
      setSelectedFileName(file.name); // Set the selected file name here
    };

    reader.readAsArrayBuffer(file);
  }

  const GetNextReceiptNo = (BenchNo) => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      BenchNumber: BenchNo,
      Dated: ScrollDated,
    };
    ////////console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "ScrollEntry/GetNextReceiptNumber",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////////console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
          SetReceiptNo(response.data.receiptNumber);
          setBenchNo(BenchNo);
        }
        // setIsUpdated(false);
      })
      .catch(function (error) {
        //////console.log(error);
      });
  };

  const ScanQRCode = (e) => {
    // if(e.target.value == 0) {
    //     setBarCodeValue("");
    // }
    // else {
    //     setBarCodeValue(e.target.value);
    // }

    setBarCodeValue(e.target.value);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      // Perform API call here
      if (BarCodeValue.length > 3) {
        postDataToAPI();
      } else {
        setBarCodeValue("");
      }
    }, 1000);

    window.scrollTo(0, document.body.scrollHeight);

    return () => clearTimeout(delay);
  }, [BarCodeValue]);

  useEffect(() => {
    const day = moment(ScrollDated).format("DD");
    if (day > 6 && day < 21) {
      const DayDiff = day - 6;
      setFineAmount(50 * DayDiff);
    }
    else {
      setFineAmount(0)
    }
  }, [ScrollDated])

  const postDataToAPI = () => {
    if (BarCodeValue != "" || BarCodeValue.length > 3) {
      const [reg, amount] = BarCodeValue.split(" ");
      setRegistrationNo(reg);
      setAmount_Deposited(amount);

      // const data = {
      //   UserID: UserID,
      //   AccessKey: AccessKey,
      //   BenchNumber: BenchNo,
      //   Dated: ScrollDated,
      //   RegistrationNo: reg,
      //   Receipt_No: ReceiptNo,
      //   Amount_Deposited: amount,
      //   LateFeeFine: LateFeeFineStatus,
      //   LateFineAmount: LateFineAmount,
      //   Bank_ID: BankID
      // };
      // var api_config = {
      //   method: "post",
      //   url: config.base_url + "ScrollEntry/AddScrollEntry",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data: data,
      // };

      // axios(api_config)
      //   .then(function (response) {
      //     //console.log(response.data);
      //     if (response.data.status_code == 1) {
      //       setIsUpdated(true);
      //       setBarCodeValue("");
      //       setRegistrationNo("");
      //       setAmount_Deposited("");
      //       SetReceiptNo(parseInt(ReceiptNo) + parseInt(1));

      //       if (response.data.RegistrationNo != "") {
      //         toast.success(
      //           "Student has been registered with Registration No " +
      //           response.data.RegistrationNo,
      //           {
      //             position: toast.POSITION.TOP_RIGHT,
      //           }
      //         );
      //       }
      //     }
      //     // setIsUpdated(false);
      //   })
      //   .catch(function (error) {
      //     //////console.log(error);
      //   });
    } else {
      //////console.log("here");
      // toast.error("Invalid QR code", {
      //     position: toast.POSITION.TOP_RIGHT
      // });
      setBarCodeValue("");
    }
  };

  const handleSubmit = () => {
    setBarCodeValue(RegistrationNo + " " + Amount_Deposited);
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      BenchNumber: BenchNo,
      Dated: ScrollDated,
      RegistrationNo: RegistrationNo,
      Receipt_No: ReceiptNo,
      Amount_Deposited: Amount_Deposited,
      LateFeeFine: true,
      FineAmount: FineAmount,
      Bank_ID: BankID,
      Serial_No: StruckOffFine,
      FineDate: FineDate,
      Month: FineMonth,
      Year: FineYear
    };

    //////console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "ScrollEntry/AddScrollEntry",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        ////////console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
          setBarCodeValue("");
          SetReceiptNo(parseInt(ReceiptNo) + parseInt(1));
        } else if (response.data.status_code == 0 || response.data.status_code == 2) {

          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setLoading(false);
          setIsUpdated(false);
        }
        // setIsUpdated(false);
      })
      .catch(function (error) {
        //////console.log(error);
      });


    setAmount_Deposited("");
    setRegistrationNo("")
    setStruckOffFine("")
  };

  const handleSubmitByFile = () => {
    excelData.forEach((row) => {
      const data = {
        UserID: UserID,
        AccessKey: AccessKey,
        BenchNumber: row.Batch,
        Dated: moment(row.DATED, "MM/DD/YYYY").format("YYYY-MM-DD"),
        RegistrationNo: row.REGISTRATION,
        Receipt_No: ReceiptNo,
        Amount_Deposited: row.AMOUNT,
        LateFeeFine: true,
        Bank_ID: BankID
      };

      //////console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "ScrollEntry/AddScrollEntry",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          ////////console.log(response.data);
          if (response.data.status_code == 1) {
            setIsUpdated(true);
            setBarCodeValue("");
            SetReceiptNo(parseInt(ReceiptNo) + parseInt(1));
          }
          else if (response.data.status_code == 0 || response.data.status_code == 2) {

            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
            setLoading(false);
            setIsUpdated(false);
          }
        })
        .catch(function (error) {
          //////console.log(error);
        });
    });
  };
  const handleLateFee = (e) => {
    setLateFeeFineStatus(e.target.checked);

    if (e.target.checked === false) {
      setLateFineAmount(0);
    }
  };

  const PostEntries = () => {

    if (TotalDeposited <= 0) {
      toast.error("Please Add Scroll of At Least 1 Student.", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    setloadingPostEntries(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      BenchNo: parseInt(BenchNo),
      StartDate: ScrollDated,
      LateFeeFine: LateFeeFineStatus,
      LateFineAmount: LateFineAmount,
      ListofStudentLedger: enteredAmounts
    };

    var api_config = {
      method: "post",
      url: config.base_url + "ScrollEntry/PostEntriesIntoLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setIsUpdated(true);
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setEnteredAmounts([]);
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        setloadingPostEntries(false);
        setLgShow(false);
      })
      .catch(function (error) {
        //////console.log(error);
      });
  };

  const EditEntry = (ID, Amount) => {
    GetADLedgerOfStudent(ID);
    setSelectedStudentID(ID);
    setStudentDepositedAmount(Amount);
    setLgShow(true)
  }

  const GetADLedgerOfStudent = (Student_ID) => {
    setLoadingFetchLedger(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      Student_ID: Student_ID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "ADLedger/GetADLedgerOfStudent",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setStudentADLedger(response.data.ListofRecords);
          setLoadingFetchLedger(false)
        }
        else {
          setLoadingFetchLedger(false)
        }
      })
      .catch(function (error) {
        //////console.log(error);
        setLoadingFetchLedger(false)
      });
  }

  const TotalOfLedger = () => {
    let total = 0;

    StudentADLedger.forEach((item) => {
      total += parseFloat(item.PendingFee);
    });

    return total;
  };

  const handleAmountChange = (e, ID, PendingFee, Student_ID, FeeCode) => {
    let value = parseFloat(e.target.value);

    // Ensure value is within bounds
    if (value > PendingFee) {
      value = PendingFee;
      e.target.value = PendingFee;
    } else if (value < 0) {
      value = 0;
      e.target.value = 0;
    }

    setEnteredAmounts((prev = []) => {
      const updatedAmounts = [...prev];
      const existingIndex = updatedAmounts.findIndex((item) => item.ID === ID);

      if (existingIndex !== -1) {
        updatedAmounts[existingIndex] = { ID, value, Student_ID, PendingFee, FeeCode };
      } else {
        updatedAmounts.push({ ID, value, Student_ID, PendingFee, FeeCode });
      }

      return updatedAmounts;
    });
  };

  // console.log(enteredAmounts);

  const calculateTotalForStudent = (Student_ID) => {
    // Filter out the records that match the provided Student_ID and sum their values
    //console.log(Student_ID)
    const TotalAmount = Object.values(enteredAmounts)
      .filter((entry) => entry.Student_ID === Student_ID)
      .reduce((sum, entry) => sum + parseFloat(entry.value || 0), 0);
    if (StudentDepositedAmount < TotalAmount) {
      toast.error("Total Amount Should be Equal to Deposited Amount.", {
        position: toast.POSITION.TOP_RIGHT
      });
      return 0;
    }
    return TotalAmount;
  };


  return (
    <>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Student Ledger
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="text-start mb-2 fw-bold">Amount Deposited: {StudentDepositedAmount}</div>
          <table className="table table-theme table-sm table-sticky ">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Dated</th>
                <th>Fee Code</th>
                <th>Description</th>
                <th>Receiveable Fee</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {LoadingFetchLedger == false ?
                <>


                  {StudentADLedger.map((item, index) => {

                    const existingAmount = enteredAmounts.find((entry) => entry.ID === item.ID);
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{moment(item.PostingDate).format("DD/MM/YYYY")}</td>
                        <td>{item.FeeCode}</td>
                        <td>{item.Description}</td>
                        <td>{item.PendingFee}</td>
                        <td>
                          <input
                            type="number"
                            value={existingAmount ? existingAmount.value : 0}
                            style={{ width: '100px' }}
                            max={item.PendingFee}
                            data-student-id={item.Student_ID}
                            className="form-control leaves text-center"
                            onChange={(e) => handleAmountChange(e, item.ID, item.PendingFee, item.Student_ID, item.FeeCode)}
                          />
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td colSpan="4" className="text-start">Total:</td>
                    <td>{TotalOfLedger()}</td>
                    <td>{calculateTotalForStudent(selectedStudentID)}</td>
                  </tr>
                </>
                :


                <tr>
                  <td colSpan="10">
                    <div className="text-center">

                      <Spinner animation="border" role="status"></Spinner>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>

          {loadingPostEntries ?
            <Spinner className="text-end" animation="border" role="status"></Spinner>
            :
            <div className="text-end">
              <button
                type="button"
                onClick={PostEntries}
                className="button button-primary"
              >
                Post Entries in Ledger
              </button>
            </div>
          }
        </Modal.Body>

      </Modal >

      <FeeSystemMenu />
      <ToastContainer />
      <div className="container body-content">
        <div className="page-head">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/Dashboard">Dashboard</NavLink>
                    </li>
                    <li className="breadcrumb-item">
                      <NavLink to="/FeeSystem/FeeSystemDashboard">
                        Fee System
                      </NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add BankScroll
                    </li>
                  </ol>
                </nav>
                <div className="page-title">Add BankScroll</div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div>
              <div className="card mb-4">
                <div className="card-body p-4">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label >Branch:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={UserName}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Dated:</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setScrollDated(e.target.value)}
                          max={moment().format(config.date_format_input)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label> Bench No:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => GetNextReceiptNo(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-2">
                      <div className="form-check mt-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="ChargeFine"
                          checked={LateFeeFineStatus}
                          onChange={(e) => handleLateFee(e)}
                        />
                        <label className="form-check-label" htmlFor="ChargeFine">
                          Struck Off Fine
                        </label>
                      </div>
                    </div>

                    {LateFeeFineStatus && (
                      <div className="col-md-2">
                        <div className="form-group">
                          <label> Fine Amount:</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setLateFineAmount(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="card">
                <div className="card-body">
                  <table className="table table-theme table-sm table-sticky">
                    <thead>
                      <tr>
                        <th>S No</th>
                        <th>Registration No</th>
                        <th>Student Name</th>
                        <th>Receipt No</th>
                        <th>Bench No</th>
                        <th>Dated</th>
                        <th>Amount Deposited</th>
                        {/* <th>Balance After Entry</th> */}
                        {/* <th>Balance Type</th> */}
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    {renderLedger()}
                  </table>
                </div>
                <div className="card-footer text-end d-flex align-items-center justify-content-between">
                  <div>
                    <strong>{totalRecords}</strong> Records Found!
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ minHeight: "50px" }}
                  >
                    <h4 className="m-0 me-4">
                      Total Amount: <strong>{TotalDeposited}</strong>
                    </h4>
                    {loadingPostEntries ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      <button
                        type="button"
                        onClick={PostEntries}
                        className="button button-primary"
                      >
                        Post Entries in Ledger
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-2">
              <div className="card-body p-4">
                <div className="row align-items-center gy-3">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Receipt No:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => SetReceiptNo(e.target.value)}
                        value={ReceiptNo}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group position-relative">
                      {loading ? (
                        <div
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "37px",
                          }}
                        >
                          <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                          ></Spinner>
                        </div>
                      ) : (
                        ""
                      )}
                      <label>BarCode Entry:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => ScanQRCode(e)}
                        value={BarCodeValue}
                        disabled={ScrollDated == "" ? true : false}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Student Code:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={RegistrationNo}
                        onChange={(e) => setRegistrationNo(e.target.value)}
                        name="RegistrationNo"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Fine:</label>
                      <input
                        type="number"
                        className="form-control"
                        value={FineAmount}
                        onChange={(e) => setFineAmount(e.target.value)}
                        name="Fine"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-1 tesx-center">
                    <div className="form-group">
                      <a
                        type="button"
                        onClick={Code_to_Amount}
                        className="print-icon text-center mt-4"
                      >
                        <span>
                          <i className="ri-arrow-left-right-line"></i>
                        </span>

                      </a>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Amount:</label>
                      <input
                        type="number"
                        className="form-control"
                        value={Amount_Deposited}
                        onChange={(e) => setAmount_Deposited(e.target.value)}
                        name="Amount_Deposited"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Fine Month:</label>
                      <select
                        className="form-select"
                        onChange={(e) => setFineMonth(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        <MonthsDropDown />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Fine Year:</label>
                      <select
                        className="form-select"
                        onChange={(e) => setFineYear(e.target.value)}
                      >
                        <option value="">Please Select</option>
                        <YearsDropDown />
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Struck Off Fine:</label>
                      <input
                        type="number"
                        className="form-control"
                        value={StruckOffFine}
                        onChange={(e) => setStruckOffFine(e.target.value)}
                        name="Fine"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-2 offset-md-4">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      disabled={Amount_Deposited == "" || RegistrationNo == "" || ScrollDated == "" || BenchNo == ""}
                      className={`button button-primary w-100
                       ${Amount_Deposited == "" || RegistrationNo == "" || ScrollDated == "" || BenchNo == ""
                          ? "disabled"
                          : ""
                        }`}
                    >
                      Add Entry
                    </button>
                  </div>

                  {/* <div className="col-md-10  text-end">
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={handleFileChange}
                    />
                  </div> */}
                  <div className="col-md-10  text-end">
                    {selectedFileName && <span style={{ fontSize: "13px", marginRight: "10px" }}>Selected file: {selectedFileName}</span>}
                    <label htmlFor="chose-file" className="button button-white shadow-sm" style={{ cursor: "pointer" }}><i className="ri-upload-2-line"></i> Chose File
                      <input
                        className="" id="chose-file"
                        style={{
                          opacity: "0",
                          zIndex: "-1",
                          visibility: "hidden",
                          position: "absolute"
                        }}
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                  <div className="col-md-2">
                    <button
                      type="button"
                      onClick={handleSubmitByFile}
                      disabled={!fileSelected}
                      className={`button button-primary w-100
                       ${!fileSelected ? "disabled" : ""}`}
                    >
                      Add Enteries
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBankScroll;