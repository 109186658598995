import React, { useState, useEffect } from "react";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../General/Pagination";
import FeeSystemMenu from "./FeeSystemMenu";
import BanksDropDown from "../Shared/BanksDropDown";
import { scrollToTop } from "../Shared/ScrollOnTop";
import Select from "react-select";
import csvDownload from "json-to-csv-export";


function CollectionReportHeadWise(props) {
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [MemberType, setMemberType] = useState(
        localStorage.getItem("MemberType")
    );
    const grp_id = localStorage.getItem("User_Group_ID");

    const [loading, setLoading] = useState(false);
    const [totalDeposit, settotalDeposit] = useState("");
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);

    const [ledger, setLedger] = useState([]);
    const [ledgerRecord, setLedgerRecord] = useState([]);
    const [showDateColumn, setShowDateColumn] = useState(true)
    const [hideZeroColumn, setHideZeroColumn] = useState(true)
    const [FeeTypes, setFeeTypes] = useState([]);
    const [FeetypeID, setFeetypeID] = useState(0);

    const [loaddata, setLoaddata] = useState(false)

    let totalReceipts = 0;

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [BenchNo, setBenchNo] = useState(0);
    const [BankID, setBankID] = useState(0);


    useEffect(() => {
        document.title = "Collection Report Headwise";
        if (StartDate == null || EndDate == null) {
            return;
        }
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            StartDate: StartDate,
            EndDate: EndDate,
            pageNo: pageNo,
            pageSize: pageSize,
            BenchNo: BenchNo,
            BusRouteID: BankID,
            FeeTypeId: FeetypeID
        };

        var api_config = {
            method: "post",
            url: config.base_url + "FeeSystem/GetCollectionReportHeadWise",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        ////console.log(data)
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setTotalRecords(response.data.totalRecords);
                    settotalDeposit(response.data.TotalReceived);
                }
                else {
                    setLedger([]);
                    setTotalRecords(0);
                    settotalDeposit(0);
                }

                setLoading(false);
            })
            .catch(function (error) {

            });
    }, [pageNo, pageSize]);

    const renderLedger = () => {
        if (ledger.length > 0) {
            return ledger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.Student_Scroll.BenchNumber}</td>
                        <td>{moment(item.ReceivedDate).format(config.date_format)}</td>
                        <td>{item.Selected_Student.Registration_No}</td>
                        <td>{item.Selected_Student.Student_Name}</td>
                        <td>{item.FeeCode} - {item.ConcessionCode}</td>
                        <td>{item.Description}</td>
                        <td>{moment(item.PostingDate).format("MMM YYYY")}</td>
                        <td>{item.Student_Scroll.Receipt_No}</td>
                        <td>{item.ReceivedAmount}</td>
                        <td>{item.Student_Scroll.Bank_Name}</td>
                        <td className="text-center">
                            {grp_id == 1 || grp_id == 10 || UserID == 1032 ? (
                                <ul
                                    className="inline-action"
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    <li>
                                        <button
                                            onClick={(e) =>
                                                deleteScrollEntry(item.Student_Scroll.Entry_Id)
                                            }
                                            className={`red`}
                                        >
                                            <i className="ri-delete-bin-line"></i>
                                        </button>
                                    </li>
                                </ul>
                            ) : (
                                ""
                            )}
                        </td>

                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="11">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const GetReport = () => {
        if (StartDate == null || EndDate == null) {
            return;
        }
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            StartDate: StartDate,
            EndDate: EndDate,
            pageNo: pageNo,
            pageSize: pageSize,
            BenchNo: BenchNo,
            BusRouteID: BankID,
            FeeTypeId: FeetypeID
        };

        var api_config = {
            method: "post",
            url: config.base_url + "FeeSystem/GetCollectionReportHeadWise",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        //console.log(data)
        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setTotalRecords(response.data.totalRecords);
                    settotalDeposit(response.data.TotalReceived);
                }
                else {
                    setLedger([]);
                    setTotalRecords(0);
                    settotalDeposit(0);
                }

                setLoading(false);
            })
            .catch(function (error) {
                toast.error("Network Error..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                //console.error(error);
            });
    };
    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const deleteScrollEntry = (ID) => {
        const confirm = window.confirm("Are you sure you want to proceed ?");
        if (confirm) {
            setLoading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                Entry_Id: ID,
            };
            ////console.log(data)
            var api_config = {
                method: "post",
                url: config.base_url + "ScrollEntry/DeleteScrollEntry",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(api_config)
                .then(function (response) {
                    // console.log(response.data);
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        GetReport()
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    // setIsUpdated(false);
                })
                .catch(function (error) {
                    //////console.log(error);
                    setLoading(false);
                });
        }
    };

    let count = 0;
    const listOfLedgerNew = ledgerRecord.map(({ ...rest }) => ({
        ...rest,
    }));

    useEffect(() => {
        setLoading(true);
        const apiData = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            PageNo: 1,
            PageSize: 100,
            SchoolID: 1,
        };

        ////////console.log(apiData);

        var api_config = {
            method: "post",
            url: config.base_url + "FeeType/GetFeeTypes",
            headers: {
                "Content-Type": "application/json",
            },
            data: apiData,
        };

        axios(api_config)
            .then(function (response) {
                //////console.log(response.data);

                setFeeTypes(response.data.ListofRecords);
                setLoading(false);
            })
            .catch(function (error) {
                //////console.log(error);
            });
    }, []);

    const dropdownOptions = [{ Fee_Type_ID: 0, Fee_Type_Name: "All Fee Types", Fee_Type_Code: "0" }, ...FeeTypes].map((item) => ({
        value: item.Fee_Type_ID,
        label: item.Fee_Type_Name + "(" + item.Fee_Type_Code + ")",
        code: item.Fee_Type_Code,
    }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "100%", // Set your desired width here
        }),
    };

    const handleDropdownChange = (selectedOption) => {
        //console.log(selectedOption);
        setFeetypeID(selectedOption.value);
    };

    const handleSubmitForCvs = () => {
        setLoaddata(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            StartDate: StartDate,
            EndDate: EndDate,
            pageNo: 1,
            pageSize: 1000000000,
            BenchNo: BenchNo,
            BusRouteID: BankID,
            FeeTypeId: FeetypeID
        };
        ////console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "FeeSystem/GetCollectionReportHeadWise",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(api_config)
            .then(function (response) {
                //////console.log(response.data);
                setLedger(response.data.ListofRecords);
                let data = response.data.ListofRecords;
                let count = 1;
                const listOfLedgerNew = data.map(({ ...rest }) => ({
                    Serial_No: count++,
                    Bench_No: rest.Student_Scroll.BenchNumber,
                    Dated: moment(rest.ReceivedDate).format(config.date_format),
                    Reg_No: rest.Selected_Student.Registration_No,
                    Name: rest.Selected_Student.Student_Name,
                    Class: rest.FeeCode + '-' + rest.ConcessionCode,
                    Fee_Name: rest.Description,
                    Fee_Month: moment(rest.PostingDate).format("MMM YYYY"),
                    Receipt_No: rest.ReceiptRef,
                    Amount_Deposited: rest.ReceivedAmount,
                    Bank: rest.Student_Scroll.Bank_Name,
                }));
                //   //////console.log(listOfLedgerNew);

                const dataToConvert = {
                    data: listOfLedgerNew,
                    filename: "CollectionReportHeadwise",
                    delimiter: ",",
                    headers: [
                        "Serial_No",
                        "Bench_No",
                        "Dated",
                        "Reg_No",
                        "Name",
                        "Class",
                        "Fee_Name",
                        "Fee_Month",
                        "Receipt No",
                        "Amount Deposited",
                        "Bank",
                    ],
                };

                csvDownload(dataToConvert);
                setLoaddata(false);
            })
            .catch(function (error) {
                //////console.log(error);
                setLoaddata(false);
            });
    }

    return (
        <>
            <ToastContainer />

            <FeeSystemMenu />
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <NavLink to="/Dashboard">Dashboard</NavLink>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <NavLink to="/FeeSystem/FeeSystemDashboard">
                                                Fee System
                                            </NavLink>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Collection Report Headwise
                                        </li>
                                    </ol>
                                </nav>
                                <div className="page-title">Collection Report Headwise</div>
                            </div>
                            <div className="col-md-6">
                                <div className="page-right-actions">
                                    <a
                                        href="javascript:;"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFilteres"
                                        className="button button-white button-icon"
                                    >
                                        Filters
                                        <i className="ri-filter-3-line"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="collapse show" id="collapseFilteres">
                    <div className="page-content">
                        <div className="container">
                            <div className="card mb-2">
                                <div className="card-body p-md-4">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Start Date :</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    max={moment().format(config.date_format_input)}
                                                    min={moment().subtract(4, 'months').format(config.date_format_input)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>End Date :</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    max={moment().format(config.date_format_input)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Select Bank :</label>
                                                <select
                                                    name="WingId"
                                                    onChange={(e) => {
                                                        setBankID(e.target.value);
                                                    }}
                                                    className="form-select"
                                                >
                                                    <option value="0">---All---</option>
                                                    <BanksDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Select Fee Type:</label>
                                                <Select
                                                    styles={customStyles}
                                                    onChange={handleDropdownChange}
                                                    options={dropdownOptions}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-3">
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-end">
                                            <lable>Hide Zero Columns</lable>
                                        </div>
                                        <div className="col-md-1  text-end">

                                            <div className="form-check text-end">

                                                <input
                                                    className="form-check-input"
                                                    checked={hideZeroColumn}
                                                    type="checkbox"
                                                    onChange={() => setHideZeroColumn(!hideZeroColumn)}
                                                />

                                            </div>
                                        </div>
                                        {
                                            loaddata ?
                                                <div className="col-md-1 text-center">
                                                    <div>
                                                        <div>
                                                            <Spinner
                                                                className="mt-4"
                                                                animation="border"
                                                                role="status"
                                                            ></Spinner>
                                                        </div>
                                                    </div>
                                                </div>
                                                :

                                                <div className="col-md-1 text-center ">
                                                    <div className="form-group text-end mt-2">
                                                        <a
                                                            type="button"
                                                            onClick={handleSubmitForCvs}
                                                            className="print-icon"
                                                        >
                                                            <span>
                                                                <i className="ri-file-excel-2-line"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                        }
                                        <div className="col-md-2 text-end">
                                            <div className="form-group text-end">
                                                <button
                                                    type="button"
                                                    onClick={GetReport}
                                                    className="button button-primary w-100"
                                                >
                                                    View Report
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="card">
                            <div className="card-body">
                                <table className="table table-theme">
                                    <thead>
                                        <tr>
                                            <th colSpan="11">{totalRecords} Records Found!</th>
                                        </tr>

                                        <tr>
                                            <th>Bench No</th>
                                            <th>Dated</th>
                                            <th>Reg No</th>
                                            <th>Student Name</th>
                                            <th>Class</th>
                                            <th>Fee Name</th>
                                            <th>Fee Month</th>
                                            <th>Receipt No.</th>
                                            <th>Amount Deposited</th>
                                            <th>Bank</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td className="text-center" colSpan="12">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>{renderLedger()}</>
                                        )}
                                        <tr>
                                            <td colSpan={7}></td>
                                            <td>
                                                <b>Total </b>
                                            </td>
                                            <td>
                                                <b>{totalDeposit}</b>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {totalRecords > 10 && (
                                    <div className="pagination-wrap">
                                        <div className="row align-items-center">
                                            <div className="col-md-2 form_default">
                                                <div className="d-flex align-items-center">
                                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                                        Page Size
                                                    </label>
                                                    <div className="form-group">
                                                        <select
                                                            className="form-select"
                                                            style={{ width: "100px" }}
                                                            onChange={(e) => {
                                                                setPageNo(1);
                                                                setPageSize(parseInt(e.target.value));
                                                            }}
                                                        >
                                                            <option value="10" selected>
                                                                10
                                                            </option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="500">500</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <Pagination
                                                pageNo={pageNo}
                                                pageSize={pageSize}
                                                totalRecords={totalRecords}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="card mt-2">
                            <div className="card-body p-md-4">
                                <div className="row text-end align-items-center">


                                    <div className="col-md-2  ">
                                        {/* <lable>Show Date Column</lable> */}
                                    </div>

                                    <div className="col-md-1  text-end">

                                        {/* <div className="form-check ">

                      <input
                        className="form-check-input"
                        checked={showDateColumn}
                        type="checkbox"
                        onChange={() => setShowDateColumn(!showDateColumn)}
                      />

                    </div> */}
                                    </div>
                                    {/* {loaddata ?
                    <div className="col-md-2 ">
                      <div className="text-center" >
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </div>

                    : <div className="col-md-2 ">
                      <button
                        type="button"
                        className="button button-primary w-100"
                        onClick={handleDownload}
                      >
                        Download Data
                      </button>
                    </div>} */}
                                    <div className="col-md-2">

                                    </div>
                                    <div className="col-md-2 offset-2">

                                    </div>

                                    <div className="col-md-1  text-end">


                                    </div>


                                    <div className="col-md-2">
                                        <NavLink
                                            className={`button button-primary w-100 ${ledger.length == 0 ? "disabled" : ""
                                                }`}
                                            to={`/FeeSystem/PrintCollectionReport/?start=${StartDate}&end=${EndDate}`}
                                            target="_blank"
                                        >
                                            Print Report
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CollectionReportHeadWise;